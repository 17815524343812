<template>
  <scrolling-document
    v-resize="fitWidth"
    class="pdf-document"
    v-bind="{ pages, pageCount, currentPage }"
    :enable-page-jump="true"
    @page-jump="onPageJump"
    @pages-fetch="onPagesFetch"
    @pages-reset="fitWidth"
    @page-focused="onPageFocused"
  >
    <template
      #default="{ page, isElementVisible, isPageFocused, isElementFocused }"
    >
      <p-d-f-page
        v-bind="{
          scale,
          optimalScale,
          page,
          isElementVisible,
          isPageFocused,
          isElementFocused
        }"
        @page-rendered="onPageRendered"
        @page-errored="onPageErrored"
        @page-focus="onPageFocused"
        @update-visibility="onUpdateVisibility"
      />
    </template>
  </scrolling-document>
</template>

<script>
// PDFDocument renders an entire PDF inline using
// PDF.js and <canvas>. Currently does not support,
// rendering of selected pages (but could be easily
// updated to do so).
import { PIXEL_RATIO, VIEWPORT_RATIO } from './utils/constants'

import resize from './directives/resize'

import ScrollingDocument from './ScrollingDocument'
import PDFPage from './PDFPage'

export default {
  name: 'PDFDocument',

  components: {
    ScrollingDocument,
    PDFPage
  },

  directives: {
    resize
  },

  props: {
    pages: {
      type: Array,
      required: true
    },
    pageCount: {
      type: Number,
      default: 0
    },
    scale: {
      type: Number,
      default: 1.0
    },
    optimalScale: {
      type: Number,
      default: 1
    },
    fit: {
      type: String,
      default: ''
    },
    currentPage: {
      type: Number,
      default: 1
    },
    isPreviewEnabled: {
      type: Boolean,
      default: false
    }
  },

  emits: [
    'scale-change',
    'pages-fetch',
    'page-focused',
    'page-rendered',
    'page-errored',
    'update-visibility'
  ],

  computed: {
    defaultViewport() {
      if (!this.pages.length) return { width: 0, height: 0 }
      const [page] = this.pages

      return page.getViewport(1.0)
    },

    isPortrait() {
      const { width, height } = this.defaultViewport
      return width <= height
    }
  },

  watch: {
    fit: function (fit) {
      switch (fit) {
        case 'width':
          this.fitWidth()
          break

        case 'auto':
          this.fitAuto()
          break

        default:
          break
      }
    },
    pageCount: 'fitWidth',
    isPreviewEnabled: 'fitWidth'
  },

  methods: {
    pageWidthScale() {
      const { defaultViewport, $el } = this
      //Because of a bug of current version
      //The width and height are NaN
      if (!(defaultViewport.viewBox || [])[2]) return 0

      return (
        ($el.clientWidth * PIXEL_RATIO * VIEWPORT_RATIO) /
        defaultViewport.viewBox[2]
      )
    },

    pageHeightScale() {
      const { defaultViewport, $el } = this
      if (!(defaultViewport.viewBox || [])[3]) return 0

      return (
        ($el.clientHeight * PIXEL_RATIO * VIEWPORT_RATIO) /
        defaultViewport.viewBox[3]
      )
    },
    // Determine an ideal scale using viewport of document's first page, the pixel ratio from the browser
    // and a subjective scale factor based on the screen size.
    fitWidth() {
      const scale = this.pageWidthScale()
      this.updateScale(scale, { isOptimal: !this.optimalScale })
    },

    fitHeight() {
      const scale = this.isPortrait
        ? this.pageHeightScale()
        : this.pageWidthScale()
      this.updateScale(scale)
    },

    fitAuto() {
      const scale = Math.min(this.pageWidthScale(), this.pageHeightScale())
      this.updateScale(scale)
    },

    updateScale(scale, { isOptimal = false } = {}) {
      if (!scale) return
      this.$emit('scale-change', { scale, isOptimal })
    },

    onPageJump(scrollTop) {
      this.$el.scrollTop = scrollTop // triggers 'scroll' event
    },

    onPagesFetch(currentPage) {
      this.$emit('pages-fetch', currentPage)
    },

    onPageFocused(pageNumber) {
      this.$emit('page-focused', pageNumber)
    },

    onPageRendered(payload) {
      this.$emit('page-rendered', payload)
    },

    onPageErrored(payload) {
      this.$emit('page-errored', payload)
    },

    onUpdateVisibility(payload) {
      this.$emit('update-visibility', payload)
    }
  }
}
</script>

<style>
.pdf-document {
  position: absolute;
  overflow: auto;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #c4c4c4;
  margin-bottom: 3em;
}

.scrolling-page {
  margin-top: 1em;
  margin-bottom: 1em;
}

@media print {
  .pdf-document {
    position: static;
  }
}
</style>
