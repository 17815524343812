<template>
  <div class="mb-5">
    <th-input-title :title="$t('pages.document_designer.mainText.title')" />
    <div class="flex items-center mb-2">
      <el-switch v-model="showMainText" />
      <th-popover
        :text="$t('pages.document_designer.mainText.hint')"
        placement="top-start"
        class="pl-3"
      />
    </div>
    <el-input
      v-model="value"
      :disabled="!showMainText"
      type="textarea"
      :autosize="{ minRows: 4, maxRows: 6 }"
      :placeholder="$t('pages.document_designer.mainText.placeholder')"
      maxlength="4096"
    />
  </div>
</template>

<script>
export default {
  computed: {
    value: {
      get() {
        return (
          this.$store.state.Templates.currentTemplate.options.main_text || ''
        )
      },
      set(value) {
        this.$store.commit('Templates/setCurrentTemplateOptionsProp', {
          main_text: value
        })
      }
    },
    showMainText: {
      get() {
        // `undefined` check in order to have the default at `true`
        const show = this.$store.state.Templates.currentTemplate.options
          .show_main_text

        return show === undefined ? true : show
      },
      set(value) {
        this.$store.commit('Templates/setCurrentTemplateOptionsProp', {
          show_main_text: value
        })
      }
    }
  }
}
</script>
