<template>
  <div class="mb-5">
    <th-input-title
      :title="$t('pages.document_designer.displayRecipientAddress.title')"
    />
    <div class="switch-group">
      <el-switch v-model="value" />
      <th-popover
        :text="$t('pages.document_designer.displayRecipientAddress.hint')"
        placement="top-start"
        class="pr-3"
      />
    </div>
    <div v-if="!value" class="error-msg">
      <div>{{ $t('pages.document_designer.addresses.error1') }}</div>
      <div>{{ $t('pages.document_designer.addresses.error2') }}</div>
    </div>
  </div>
</template>

<script>
import set from 'just-safe-set'
import get from 'just-safe-get'
import typeOf from 'just-typeof'
import cloneDeep from 'clone-deep'

export default {
  computed: {
    value: {
      get() {
        if (
          !Object.prototype.hasOwnProperty.call(
            this.$store.state.Templates.currentTemplate.options,
            'addresses'
          ) ||
          !Object.prototype.hasOwnProperty.call(
            this.$store.state.Templates.currentTemplate.options.addresses,
            'self'
          ) ||
          !Object.prototype.hasOwnProperty.call(
            this.$store.state.Templates.currentTemplate.options.addresses.self,
            'enabled'
          )
        )
          return true

        return get(
          this.$store.state.Templates.currentTemplate.options.addresses,
          'local.enabled'
        )
      },
      set(value) {
        let addresses = cloneDeep(
          this.$store.state.Templates.currentTemplate.options.addresses
        )
        if (typeOf(addresses) !== 'object') addresses = {}

        set(addresses, 'local.enabled', value)

        this.$store.commit('Templates/setCurrentTemplateOptionsProp', {
          addresses: addresses
        })
      }
    }
  }
}
</script>

<style scoped>
.switch-group {
  display: flex;
  align-items: center;
}
.switch-group > * {
  margin-right: 1em;
  color: #686868;
}

.error-msg {
  color: red;
  font-size: 12px;
}
</style>
