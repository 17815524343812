<template>
  <div class="mb-5">
    <th-input-title
      for="transactionNumberTitle"
      :title="$t('pages.document_designer.transactionNumberTitle.title')"
    />
    <el-input
      id="transactionNumberTitle"
      v-model="value"
      :placeholder="placeholder"
      maxlength="32"
    />
  </div>
</template>

<script>
export default {
  computed: {
    value: {
      get() {
        return (
          this.$store.state.Templates.currentTemplate.options
            .transaction_number_title || ''
        )
      },
      set(value) {
        this.$store.commit('Templates/setCurrentTemplateOptionsProp', {
          transaction_number_title: value
        })
      }
    },
    placeholder() {
      if (this.$route.name === 'invoices') {
        return this.$t(
          'pages.document_designer.transactionNumberTitle.placeholders.invoices'
        )
      }
      if (this.$route.name === 'delivery_notes') {
        return this.$t(
          'pages.document_designer.transactionNumberTitle.placeholders.delivery_notes'
        )
      }
      if (this.$route.name === 'full_receipts') {
        return this.$t(
          'pages.document_designer.transactionNumberTitle.placeholders.full_receipts'
        )
      }
      return ''
    }
  }
}
</script>
