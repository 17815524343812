<template>
  <div class="mb-5">
    <th-input-title :title="$t('pages.document_designer.paperSize')" />
    <el-radio-group v-model="value">
      <el-radio-button label="A4" />
      <el-radio-button label="Letter" />
    </el-radio-group>
  </div>
</template>

<script>
export default {
  computed: {
    value: {
      get() {
        const value = this.$store.state.Templates.currentTemplate.options
          .paper_size
        if (!value) return 'A4'
        if (value === 'letter') {
          return 'Letter'
        }
        return value
      },
      set(value) {
        this.$store.commit('Templates/setCurrentTemplateOptionsProp', {
          paper_size: value === 'Letter' ? 'letter' : value
        })
      }
    }
  }
}
</script>
