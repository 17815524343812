<template>
  <el-menu
    default-active="2"
    class="el-menu-vertical"
    :collapse="isCollapse"
    @open="handleOpen"
    @close="handleClose"
    @select="handleSelect"
  >
    <el-menu-item index="general" @click="active = 0">
      <img :src="active === 0 ? GeneralIconActive : GeneralIconGrey" />
      <template #title>
        <span>{{ $t('pages.document_designer.general') }}</span>
      </template>
    </el-menu-item>
    <el-menu-item index="logo" @click="active = 1">
      <img :src="active === 1 ? HeaderIconActive : HeaderIconGrey" />
      <template #title>
        <span>{{ $t('pages.document_designer.headersTitle') }}</span>
      </template>
    </el-menu-item>
    <el-menu-item index="headers" @click="active = 2">
      <img :src="active === 2 ? BodyIconActive : BodyIconGrey" />
      <template #title>
        <span>{{ $t('pages.document_designer.body') }}</span>
      </template>
    </el-menu-item>
    <el-menu-item index="footer" @click="active = 3">
      <img :src="active === 3 ? FooterIconActive : FooterIconGrey" />
      <template #title>
        <span>{{ $t('pages.document_designer.footer') }}</span>
      </template>
    </el-menu-item>
  </el-menu>
</template>

<script>
import GeneralIconActive from '@/assets/nav/general-active.svg'
import GeneralIconGrey from '@/assets/nav/general-grey.svg'
import HeaderIconActive from '@/assets/nav/header-active.svg'
import HeaderIconGrey from '@/assets/nav/header-grey.svg'
import FooterIconActive from '@/assets/nav/footer-active.svg'
import FooterIconGrey from '@/assets/nav/footer-grey.svg'
import BodyIconActive from '@/assets/nav/body-active.svg'
import BodyIconGrey from '@/assets/nav/body-grey.svg'
export default {
  data() {
    return {
      GeneralIconActive,
      GeneralIconGrey,
      HeaderIconActive,
      HeaderIconGrey,
      FooterIconActive,
      FooterIconGrey,
      BodyIconActive,
      BodyIconGrey,
      isCollapse: true,
      active: 0
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      // console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath)
    },
    handleSelect(key, keyPath) {
      this.$emit('view-change', key)
    }
  }
}
</script>

<style scoped>
.el-menu-vertical {
  height: 100%;
  width: 64px;
}
.el-menu-vertical img {
  height: 100%;
}
</style>
