<template>
  <div>
    <h3>{{ $t('pages.document_designer.body') }}</h3>
    <row-highlight />
    <attention />
    <main-text />
    <div v-if="!headerArrays" class="my-5">No headers available</div>
    <div v-else>
      <th-input-title :title="$t('pages.document_designer.items')" />
      <div class="mb-5">
        <header-component
          v-for="(header, index) in headerArrays.items"
          :key="index"
          :header="header"
        />
      </div>

      <th-input-title :title="$t('pages.document_designer.summary')" />
      <div class="mb-5">
        <header-component
          v-for="(header, index) in headerArrays.summary"
          :key="index"
          :header="header"
        />
      </div>
    </div>
    <currency-formats />
    <slot name="additionals" />
  </div>
</template>

<script>
import HeaderComponent from './components/table-header.vue'
import MainText from './components/main-text.vue'
import Attention from './components/attention.vue'
import RowHighlight from './components/row-highlight.vue'
import CurrencyFormats from './components/currency-formats.vue'

import { mapState } from 'vuex'
import get from 'just-safe-get'
import { HEADERS_MAP } from '../../../../../constants'

export default {
  components: {
    HeaderComponent,
    MainText,
    Attention,
    RowHighlight,
    CurrencyFormats
  },
  computed: {
    headerArrays() {
      if (
        this.templateType === 'invoice_v1' ||
        this.templateType === 'delivery_note_v1' ||
        this.templateType === 'full_receipt_v1'
      ) {
        const headers = {
          items: [],
          summary: []
        }
        const _HEADERS_MAP = HEADERS_MAP(this)
        Object.keys(_HEADERS_MAP).map((header) => {
          if (_HEADERS_MAP[header].section === 'items') {
            headers.items.push(header)
          } else if (_HEADERS_MAP[header].section === 'summary') {
            headers.summary.push(header)
          }
        })

        return headers
      } else {
        return null
      }
    },
    ...mapState({
      templateType(state) {
        return get(state.Templates, 'currentTemplate.type')
      }
    })
  }
}
</script>
