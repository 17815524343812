<template>
  <div class="pdf-paginator text-sm">
    <template v-if="pageCount">
      <input
        :value="modelValue"
        min="1"
        :max="pageCount"
        type="number"
        @input="input"
      />
      <span> / {{ pageCount }}</span>
    </template>
    <input v-else type="number" />
  </div>
</template>

<script>
export default {
  name: 'PDFPaginator',

  props: {
    modelValue: {
      type: Number,
      default: 0
    },
    pageCount: {
      type: Number,
      default: 0
    }
  },
  emits: ['update:modelValue'],

  watch: {
    pageCount: function () {
      this.$emit('update:modelValue', 1)
    }
  },

  methods: {
    input(event) {
      this.$emit('update:modelValue', parseInt(event.target.value, 10))
    }
  }
}
</script>

<style>
.pdf-paginator input {
  margin: 0;
  padding: 0.5rem;
  height: 40px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  text-align: center;
}
</style>
