<template>
  <div class="scrolling-document" @scroll="updateScrollBounds">
    <scrolling-page
      v-for="page in pages"
      :key="page.pageNumber"
      v-bind="{
        page,
        clientHeight,
        scrollTop,
        focusedPage,
        enablePageJump
      }"
      @page-jump="onPageJump"
      @page-focused="onPageFocused"
    >
      <template
        #default="{ isElementVisible, isPageFocused, isElementFocused }"
      >
        <div class="scrolling-page">
          <slot
            v-bind="{ page, isElementVisible, isPageFocused, isElementFocused }"
          />
        </div>
      </template>
    </scrolling-page>
  </div>
</template>

<script>
import ScrollingPage from './ScrollingPage'

export default {
  components: {
    ScrollingPage
  },

  props: {
    pages: {
      type: Array,
      required: true
    },
    enablePageJump: {
      type: Boolean,
      default: false
    },
    currentPage: {
      type: Number,
      default: 1
    },
    isParentVisible: {
      type: Boolean,
      default: true
    }
  },
  emits: ['pages-reset', 'pages-fetch', 'page-jump', 'page-focused'],
  data() {
    return {
      focusedPage: undefined,
      scrollTop: 0,
      clientHeight: 0
    }
  },

  computed: {
    pagesLength() {
      return this.pages.length
    }
  },

  watch: {
    isParentVisible: 'updateScrollBounds',

    pagesLength: function (count, oldCount) {
      if (oldCount === 0) this.$emit('pages-reset')

      // Set focusedPage after new pages are mounted
      this.$nextTick(() => {
        this.focusedPage = this.currentPage
      })
    },

    currentPage: function (currentPage) {
      if (currentPage > this.pages.length) {
        this.fetchPages(currentPage)
      } else {
        this.focusedPage = currentPage
      }
    }
  },

  methods: {
    fetchPages(currentPage) {
      this.$emit('pages-fetch', currentPage)
    },

    onPageJump(scrollTop) {
      this.$emit('page-jump', scrollTop)
    },

    onPageFocused(page) {
      this.$emit('page-focused', page)
    },

    updateScrollBounds() {
      const { scrollTop, clientHeight } = this.$el
      this.scrollTop = scrollTop
      this.clientHeight = clientHeight
    }
  }
}
</script>
