<template>
  <div class="pdf-viewer">
    <header class="pdf-viewer__header box-shadow">
      <el-button
        icon="Notebook"
        class="el-button--text-icon"
        @click="togglePreview"
      />

      <div class="flex-grow" />

      <p-d-f-zoom
        :scale="scale"
        class="header-item"
        @change="updateScale"
        @fit="updateFit"
      />

      <div class="flex-grow" />

      <p-d-f-paginator
        v-model="currentPage"
        :page-count="pageCount"
        class="header-item"
      />

      <slot name="header" />
    </header>

    <p-d-f-data
      class="pdf-viewer__main"
      :url="url"
      @page-count="updatePageCount"
      @page-focus="updateCurrentPage"
      @document-rendered="onDocumentRendered"
      @document-errored="onDocumentErrored"
    >
      <template #preview="{ pages }">
        <p-d-f-preview
          v-show="isPreviewEnabled"
          class="pdf-viewer__preview"
          v-bind="{ pages, scale, currentPage, pageCount, isPreviewEnabled }"
          @page-focus="updateCurrentPage"
        />
      </template>

      <template #document="{ pages }">
        <p-d-f-document
          class="pdf-viewer__document"
          :class="{ 'preview-enabled': isPreviewEnabled }"
          v-bind="{
            pages,
            scale,
            optimalScale,
            fit,
            currentPage,
            pageCount,
            isPreviewEnabled
          }"
          @scale-change="updateScale"
          @page-focused="onPageFocused"
        />
      </template>
    </p-d-f-data>
  </div>
</template>

<script>
import PreviewIcon from '@/assets/pdf/icon-preview.svg'

import PDFDocument from './PDFDocument'
import PDFData from './PDFData'
import PDFPaginator from './PDFPaginator'
import PDFPreview from './PDFPreview'
import PDFZoom from './PDFZoom'

function floor(value, precision) {
  const multiplier = Math.pow(10, precision || 0)
  return Math.floor(value * multiplier) / multiplier
}

export default {
  name: 'PDFViewer',

  components: {
    PDFDocument,
    PDFData,
    PDFPaginator,
    PDFPreview,
    PDFZoom
  },

  props: {
    url: {
      type: String,
      required: true
    }
  },
  emits: ['document-errored'],

  data() {
    return {
      PreviewIcon,
      scale: undefined,
      optimalScale: undefined,
      fit: undefined,
      currentPage: 1,
      pageCount: undefined,
      isPreviewEnabled: false
    }
  },

  watch: {
    url: function () {
      this.currentPage = undefined
    }
  },

  mounted() {
    document.body.classList.add('overflow-hidden')
  },

  methods: {
    onDocumentRendered() {
      this.$emit('document-errored', this.url)
    },

    onDocumentErrored(e) {
      this.$emit('document-errored', e)
    },

    onPageFocused(page) {
      this.currentPage = page
    },

    updateScale({ scale, isOptimal = false }) {
      const roundedScale = floor(scale, 2)
      if (isOptimal) this.optimalScale = roundedScale
      this.scale = roundedScale
    },

    updateFit(fit) {
      this.fit = fit
    },

    updatePageCount(pageCount) {
      this.pageCount = pageCount
    },

    updateCurrentPage(pageNumber) {
      this.currentPage = pageNumber
    },

    togglePreview() {
      this.isPreviewEnabled = !this.isPreviewEnabled
    }
  }
}
</script>

<style scoped>
header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.5em;
  position: relative;
  user-select: none;
}

.header-item {
  margin: 0 1em;
}

.pdf-viewer {
  border-left: 1px solid var(--border-color);
}

.pdf-viewer__header {
  border-bottom: 1px solid var(--border-color);
}

.pdf-viewer__main,
.pdf-viewer {
  position: relative;
  width: 100%;
  height: 100%;
}

/* need to override scrollbar color for dark background */
.pdf-viewer__main > :hover::-webkit-scrollbar-thumb {
  background-color: rgba(250, 250, 250, 0.7);
}

.pdf-viewer .pdf-viewer__document,
.pdf-viewer .pdf-viewer__preview {
  top: 0;
}

.pdf-viewer__preview {
  display: block;
  width: 15%;
  right: 85%;
}

.pdf-viewer__document {
  top: 0;
  width: 100%;
  left: 0;
}

.pdf-viewer__document.preview-enabled {
  width: 85%;
  left: 15%;
}

@media print {
  header {
    display: none;
  }
}
</style>
