<script>
export default {
  name: 'ScrollingPage',

  props: {
    page: {
      type: Object, // instance of PDFPageProxy returned from pdf.getPage
      required: true
    },
    focusedPage: {
      type: Number,
      default: undefined
    },
    scrollTop: {
      type: Number,
      default: 0
    },
    clientHeight: {
      type: Number,
      default: 0
    },
    enablePageJump: {
      type: Boolean,
      default: false
    }
  },

  emits: ['page-jump', 'page-focused'],

  data() {
    return {
      elementTop: 0,
      elementHeight: 0
    }
  },

  computed: {
    isPageFocused() {
      return this.page.pageNumber === this.focusedPage
    },

    isElementFocused() {
      const {
        elementTop,
        bottom,
        elementHeight,
        scrollTop,
        clientHeight
      } = this
      if (!elementHeight) return

      const halfHeight = elementHeight / 2
      const halfScreen = clientHeight / 2
      const delta = elementHeight >= halfScreen ? halfScreen : halfHeight
      const threshold = scrollTop + delta

      return elementTop < threshold && bottom >= threshold
    },

    isElementVisible() {
      const {
        elementTop,
        bottom,
        elementHeight,
        scrollTop,
        scrollBottom
      } = this
      if (!elementHeight) return

      return elementTop < scrollBottom && bottom > scrollTop
    },

    bottom() {
      return this.elementTop + this.elementHeight
    },

    scrollBottom() {
      return this.scrollTop + this.clientHeight
    }
  },

  watch: {
    scrollTop: 'updateElementBounds',
    clientHeight: 'updateElementBounds',
    isPageFocused: 'jumpToPage'
  },

  created() {
    this.$emitter.on('update-visibility', this.updateElementBounds)
  },

  mounted() {
    this.updateElementBounds()
  },

  methods: {
    jumpToPage() {
      if (!this.enablePageJump || this.isElementFocused || !this.isPageFocused)
        return
      this.updateElementBounds()
      this.$emit('page-jump', this.elementTop)
    },

    updateElementBounds() {
      if (this.isElementFocused) {
        this.$emit('page-focused', this.page.pageNumber)
      }
      this.elementTop = this.$el.nextSibling.offsetTop
      this.elementHeight = this.$el.nextSibling.offsetHeight
    }
  },

  render() {
    const { isElementVisible, isPageFocused, isElementFocused } = this
    return this.$slots.default({
      isElementVisible,
      isPageFocused,
      isElementFocused
    })
  }
}
</script>
