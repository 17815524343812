<template>
  <div class="pdf-zoom">
    <!-- Zoom in -->
    <el-button
      :disabled="isDisabled"
      class="el-button--text-icon"
      icon="ZoomIn"
      @click="zoomIn"
    />

    <!-- Zoom out -->
    <el-button
      :disabled="isDisabled"
      class="el-button--text-icon"
      icon="ZoomOut"
      @click="zoomOut"
    />

    <!-- Fit width -->
    <el-button
      :disabled="isDisabled"
      class="el-button--text-icon"
      @click="fitWidth"
    >
      <svg viewBox="0 0 24 24">
        <path
          d="M18.586 4l-5.293 5.293c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0l5.293-5.293v3.586c0 0.552 0.448 1 1 1s1-0.448 1-1v-6c0-0.136-0.027-0.265-0.076-0.383s-0.121-0.228-0.216-0.323c-0.001-0.001-0.001-0.001-0.002-0.002-0.092-0.092-0.202-0.166-0.323-0.216-0.118-0.049-0.247-0.076-0.383-0.076h-6c-0.552 0-1 0.448-1 1s0.448 1 1 1zM5.414 20l5.293-5.293c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293v-3.586c0-0.552-0.448-1-1-1s-1 0.448-1 1v6c0 0.552 0.448 1 1 1h6c0.552 0 1-0.448 1-1s-0.448-1-1-1z"
        />
      </svg>
    </el-button>

    <!-- Fit auto -->
    <el-button
      :disabled="isDisabled"
      class="el-button--text-icon"
      @click="fitAuto"
    >
      <svg viewBox="0 0 24 24">
        <path
          d="M16.414 9l5.293-5.293c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293v-3.586c0-0.552-0.448-1-1-1s-1 0.448-1 1v6c0 0.136 0.027 0.265 0.076 0.383s0.121 0.228 0.216 0.323c0.001 0.001 0.001 0.001 0.002 0.002 0.092 0.092 0.202 0.166 0.323 0.216 0.118 0.049 0.247 0.076 0.383 0.076h6c0.552 0 1-0.448 1-1s-0.448-1-1-1zM3.707 21.707l5.293-5.293v3.586c0 0.552 0.448 1 1 1s1-0.448 1-1v-6c0-0.136-0.027-0.265-0.076-0.383s-0.121-0.228-0.216-0.323c-0.001-0.001-0.001-0.001-0.002-0.002-0.096-0.095-0.206-0.167-0.323-0.216-0.118-0.049-0.247-0.076-0.383-0.076h-6c-0.552 0-1 0.448-1 1s0.448 1 1 1h3.586l-5.293 5.293c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0z"
        />
      </svg>
    </el-button>
  </div>
</template>

<script>
import ZoomInIcon from '@/assets/pdf/icon-zoom-in.svg'
import ZoomOutIcon from '@/assets/pdf/icon-zoom-out.svg'
import ExpandIcon from '@/assets/pdf/icon-expand.svg'
import ShrinkIcon from '@/assets/pdf/icon-shrink.svg'

export default {
  name: 'PDFZoom',

  props: {
    scale: {
      type: Number,
      default: 1
    },
    increment: {
      type: Number,
      default: 0.25
    }
  },
  emits: ['change', 'fit'],
  data() {
    return {
      ZoomInIcon,
      ZoomOutIcon,
      ExpandIcon,
      ShrinkIcon
    }
  },
  computed: {
    isDisabled() {
      return !this.scale
    }
  },

  methods: {
    zoomIn() {
      this.updateScale(this.scale + this.increment)
    },

    zoomOut() {
      if (this.scale <= this.increment) return
      this.updateScale(this.scale - this.increment)
    },

    updateScale(scale) {
      this.$emit('change', { scale })
    },

    fitWidth() {
      this.$emit('fit', 'width')
    },

    fitAuto() {
      this.$emit('fit', 'auto')
    }
  }
}
</script>
