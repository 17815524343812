<template>
  <div class="mb-5">
    <th-input-title :title="$t('pages.document_designer.attention.title')" />
    <div class="flex items-center mb-2">
      <el-switch v-model="showAttention" />
      <th-popover
        :text="$t('pages.document_designer.attention.hint')"
        placement="top-start"
        class="pl-3"
      />
    </div>
    <el-input
      v-model="value"
      :disabled="!showAttention"
      :placeholder="$t('pages.document_designer.attention.title')"
      maxlength="128"
    />
    <div v-if="invalid" class="warning">
      {{ $t('pages.document_designer.attention.warning') }}
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    value: {
      get() {
        return (
          this.$store.state.Templates.currentTemplate.options.attention || ''
        )
      },
      set(value) {
        this.$store.commit('Templates/setCurrentTemplateOptionsProp', {
          attention: value
        })
      }
    },
    showAttention: {
      get() {
        // `undefined` check in order to have the default at `true`
        const show = this.$store.state.Templates.currentTemplate.options
          .show_attention

        return show === undefined ? true : show
      },
      set(value) {
        this.$store.commit('Templates/setCurrentTemplateOptionsProp', {
          show_attention: value
        })
      }
    },
    invalid() {
      return this.value.length > 128
    }
  }
}
</script>

<style scoped>
.warning {
  color: red;
  font-size: 12px;
  float: right;
  margin-top: 2px;
  margin-right: 3px;
}
</style>
