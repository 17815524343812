<script>
// PDFDocument renders an entire PDF inline using
// PDF.js and <canvas>. Currently does not support,
// rendering of selected pages (but could be easily
// updated to do so).
import debug from 'debug'
import { h } from 'vue'

import range from 'lodash/range'
const log = debug('app:components/PDFData')

// pdf: instance of PDFData
// see docs for PDF.js for more info
function getPages(pdf, first, last) {
  const allPages = range(first, last + 1).map((number) => pdf.getPage(number))
  return Promise.all(allPages)
}

const BUFFER_LENGTH = 10
function getDefaults() {
  return {
    pages: [],
    cursor: 0
  }
}

export default {
  name: 'PDFData',

  props: {
    url: {
      type: String,
      required: true
    }
  },

  emits: [
    'page-rendered',
    'page-errored',
    'pages-fetch',
    'page-count',
    'document-errored'
  ],

  data() {
    return Object.assign(getDefaults(), {
      pdf: undefined
    })
  },

  computed: {
    pageCount() {
      return this.pdf ? this.pdf.numPages : 0
    }
  },

  watch: {
    url: {
      handler: async function (url) {
        this.getDocument(url)
      },
      immediate: true
    },

    pdf(pdf, oldPdf) {
      if (!pdf) return
      if (oldPdf) Object.assign(this, getDefaults())

      this.$emit('page-count', this.pageCount)
      this.fetchPages()
    }
  },

  created() {
    this.$emitter.on('page-rendered', this.onPageRendered)
    this.$emitter.on('page-errored', this.onPageErrored)
    this.$emitter.on('pages-fetch', this.fetchPages)
  },

  methods: {
    fetchPages(currentPage = 0) {
      if (!this.pdf) return
      if (this.pageCount > 0 && this.pages.length === this.pageCount) return

      const startIndex = this.pages.length
      if (this.cursor > startIndex) return

      const startPage = startIndex + 1
      const endPage = Math.min(
        Math.max(currentPage, startIndex + BUFFER_LENGTH),
        this.pageCount
      )
      this.cursor = endPage

      log(`Fetching pages ${startPage} to ${endPage}`)
      getPages(this.pdf, startPage, endPage)
        .then((pages) => {
          const deleteCount = 0
          this.pages.splice(startIndex, deleteCount, ...pages)
          return this.pages
        })
        .catch((response) => {
          this.$emit('document-errored', {
            text: 'Failed to retrieve pages',
            response
          })
          log('Failed to retrieve pages', response)
        })
    },
    onPageRendered({ text, page }) {
      log(text, page)
    },
    onPageErrored({ text, response, page }) {
      log('Error!', text, response, page)
    },
    async getDocument(url) {
      const pdfjs = await import(
        /* webpackChunkName: 'pdfjs-dist' */
        'pdfjs-dist/webpack'
      )
      pdfjs.getDocument(url).promise.then((pdf) => {
        this.pdf = pdf
      })
    }
  },

  render() {
    return h('div', [
      this.$slots.preview({
        pages: this.pages
      }),
      this.$slots.document({
        pages: this.pages
      })
    ])
  }
}
</script>
