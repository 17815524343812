<template>
  <div class="mb-5">
    <th-input-title
      :title="$t('pages.document_designer.customer_number.title')"
    />
    <div class="flex items-center mb-2">
      <el-switch v-model="showCustomerNumber" />
      <th-popover
        :text="$t('pages.document_designer.customer_number.hint')"
        placement="top-start"
        class="pl-3"
      />
    </div>
    <el-input
      v-model="customerNumberTitle"
      :disabled="!showCustomerNumber"
      :placeholder="$t('pages.document_designer.customer_number.title')"
      maxlength="32"
    />
  </div>
</template>

<script>
export default {
  computed: {
    customerNumberTitle: {
      get() {
        return (
          this.$store.state.Templates.currentTemplate.options
            .customer_number_title || ''
        )
      },
      set(value) {
        this.$store.commit('Templates/setCurrentTemplateOptionsProp', {
          customer_number_title: value
        })
      }
    },
    showCustomerNumber: {
      get() {
        // `undefined` check in order to have the default at `true`
        const show = this.$store.state.Templates.currentTemplate.options
          .show_customer_number

        return show === undefined ? true : show
      },
      set(value) {
        this.$store.commit('Templates/setCurrentTemplateOptionsProp', {
          show_customer_number: value
        })
      }
    }
  }
}
</script>
