<template>
  <div class="mb-5">
    <th-input-title :title="$t('pages.document_designer.cashierTitle.title')" />
    <div class="flex items-center mb-2">
      <el-switch v-model="showCashier" />
      <th-popover
        :text="$t('pages.document_designer.cashierTitle.hint')"
        placement="top-start"
        class="pl-3"
      />
    </div>
    <el-input
      v-model="value"
      :disabled="!showCashier"
      :placeholder="$t('pages.document_designer.cashierTitle.title')"
      maxlength="32"
    />
  </div>
</template>

<script>
export default {
  computed: {
    value: {
      get() {
        return (
          this.$store.state.Templates.currentTemplate.options.cashier_title ||
          ''
        )
      },
      set(value) {
        this.$store.commit('Templates/setCurrentTemplateOptionsProp', {
          cashier_title: value
        })
      }
    },
    showCashier: {
      get() {
        // `undefined` check in order to have the default at `true`
        const show = this.$store.state.Templates.currentTemplate.options
          .show_cashier

        return show === undefined ? true : show
      },
      set(value) {
        this.$store.commit('Templates/setCurrentTemplateOptionsProp', {
          show_cashier: value
        })
      }
    }
  }
}
</script>
