<template>
  <div class="mb-5">
    <th-input-title
      :title="$t('pages.document_designer.tableRowHighlight.title')"
    />
    <div class="flex items-center">
      <el-switch v-model="value" />
      <th-popover
        :text="$t('pages.document_designer.tableRowHighlight.hint')"
        placement="top-start"
        class="pl-3"
      />
    </div>
  </div>
</template>

<script>
import get from 'just-safe-get'
export default {
  computed: {
    value: {
      get() {
        const options = get(
          this.$store.state.Templates,
          'currentTemplate.options'
        )
        if (!options) return true
        if (!Object.prototype.hasOwnProperty.call(options, 'row_highlight'))
          return true
        return options.row_highlight
      },
      set(value) {
        this.$store.commit('Templates/setCurrentTemplateOptionsProp', {
          row_highlight: value
        })
      }
    }
  }
}
</script>
