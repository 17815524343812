<template>
  <div class="mb-5">
    <th-input-title :title="$t('pages.document_designer.trailerText.title')" />
    <el-input
      v-model="value"
      type="textarea"
      :autosize="{ minRows: 6, maxRows: 10 }"
      :placeholder="$t('pages.document_designer.trailerText.placeholder')"
      maxlength="16384"
    />
  </div>
</template>

<script>
export default {
  computed: {
    value: {
      get() {
        return (
          this.$store.state.Templates.currentTemplate.options.trailer_text || ''
        )
      },
      set(value) {
        this.$store.commit('Templates/setCurrentTemplateOptionsProp', {
          trailer_text: value
        })
      }
    }
  }
}
</script>
