<template>
  <scrolling-document
    class="pdf-preview"
    v-bind="{ pages, pageCount, currentPage }"
    :is-parent-visible="isPreviewEnabled"
    @pages-fetch="onPagesFetch"
    @page-focused="onPageFocused"
  >
    <template #default="{ page, isElementVisible, isPageFocused }">
      <p-d-f-thumbnail
        v-bind="{ scale, page, isElementVisible, isPageFocused }"
        @thumbnail-rendered="onThumbnailRendered"
        @thumbnail-errored="onThumbnailErrored"
        @page-focus="onPageFocused"
      />
    </template>
  </scrolling-document>
</template>

<script>
import ScrollingDocument from './ScrollingDocument'
import PDFThumbnail from './PDFThumbnail'

export default {
  name: 'PDFPreview',

  components: {
    ScrollingDocument,
    PDFThumbnail
  },

  props: {
    pages: {
      type: Array,
      required: true
    },
    pageCount: {
      type: Number,
      default: 0
    },
    scale: {
      type: Number,
      default: 1.0
    },
    currentPage: {
      type: Number,
      default: 1
    },
    isPreviewEnabled: {
      type: Boolean,
      default: false
    }
  },

  emits: [
    'pages-fetch',
    'page-focus',
    'thumbnail-rendered',
    'thumbnail-errored'
  ],

  methods: {
    onPagesFetch(currentPage) {
      this.$emit('pages-fetch', currentPage)
    },

    onPageFocused(pageNumber) {
      this.$emit('page-focus', pageNumber)
    },

    onThumbnailRendered(payload) {
      this.$el.dispatchEvent(new Event('scroll'))
      this.$emit('thumbnail-rendered', payload)
    },

    onThumbnailErrored(payload) {
      this.$emit('thumbnail-errored', payload)
    }
  }
}
</script>

<style scoped>
.pdf-preview {
  overflow: auto;
  z-index: 1;
  padding: 0.5em 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background-color);
}

@media print {
  .pdf-preview {
    display: none;
  }
}
</style>
