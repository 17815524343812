<template>
  <div>
    <!-- Font -->
    <th-input-title for="font" :title="$t('pages.document_designer.font')" />
    <el-select
      id="font"
      v-model="value"
      class="mb-5"
      :placeholder="$t('common.interactions.buttons.select')"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>

    <!-- Font color -->
    <th-input-title
      for="fontColor"
      :title="$t('pages.document_designer.fontColor.title')"
    />
    <el-input
      id="fontColor"
      v-model="color"
      :placeholder="$t('pages.document_designer.fontColor.placeholder')"
      class="mb-5 input-with-color"
    >
      <template #append>
        <el-color-picker
          v-model="color"
          :show-alpha="false"
          color-format="hex"
        />
      </template>
    </el-input>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        {
          value: 'Open Sans',
          label: 'Open Sans'
        },
        {
          value: 'monospace',
          label: 'Monospace'
        },
        {
          value: 'arial',
          label: 'Arial'
        }
      ]
    }
  },
  computed: {
    value: {
      get() {
        return (
          this.$store.state.Templates.currentTemplate.options.font ||
          'Open Sans'
        )
      },
      set(value) {
        this.$store.commit('Templates/setCurrentTemplateOptionsProp', {
          font: value
        })
      }
    },
    color: {
      get() {
        return (
          this.$store.state.Templates.currentTemplate.options.font_color ||
          '#000000'
        )
      },
      set(value) {
        this.$store.commit('Templates/setCurrentTemplateOptionsProp', {
          font_color: value
        })
      }
    }
  },
  methods: {}
}
</script>

<style>
.input-with-color .el-color-picker__trigger {
  padding: 0;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  overflow: hidden;
}
.input-with-color .el-color-picker {
  height: 30px;
  display: block;
  margin: 0 5px;
}
.input-with-color .el-input-group__append {
  padding: 0;
}
.input-with-color .el-color-picker__icon {
  display: none;
}
.el-color-picker__color {
  border: 0 none;
}

.el-color-dropdown {
  padding: 1.2rem;
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color);
  background: var(--background-color);
}
</style>
