<template>
  <div class="mb-5">
    <th-input-title
      :title="$t('pages.document_designer.footerContent.title')"
    />

    <!-- Content -->
    <div
      v-for="(item, index) in localFooterContent"
      :key="index"
      class="footer-content-holder"
    >
      <el-input
        v-model="localFooterContent[index].content"
        type="textarea"
        :autosize="{ minRows: 6, maxRows: 10 }"
        :placeholder="$t('pages.document_designer.footerContent.placeholder')"
        maxlength="16384"
      />
      <div class="delete">
        <el-button text plain icon="Delete" @click="deleteItem(index)" />
      </div>
    </div>

    <!-- Add -->
    <el-button
      class="el-button--primary-icon"
      plain
      icon="Plus"
      :disabled="isMax"
      @click="addNewFooterContent"
    />
  </div>
</template>

<script>
import cloneDeep from 'clone-deep'
export default {
  data() {
    return {
      localFooterContent: [],
      deleteEnabled: []
    }
  },
  computed: {
    value: {
      get() {
        return (
          this.$store.state.Templates.currentTemplate.options.footer_contents ||
          []
        )
      },
      set(value) {
        this.$store.commit('Templates/setCurrentTemplateOptionsProp', {
          footer_contents: cloneDeep(value)
        })
      }
    },
    isMax() {
      return this.localFooterContent.length >= 3
    }
  },
  watch: {
    localFooterContent: {
      deep: true,
      handler: function (val, oldVal) {
        this.value = val
      }
    }
  },
  created() {
    this.localFooterContent = this.value || []
  },
  methods: {
    addNewFooterContent() {
      if (this.isMax) return

      this.localFooterContent.push({
        align: null, // not supported yet, but preparing data
        content: null
      })
    },
    deleteItem(targetIndex) {
      this.localFooterContent = this.localFooterContent.filter(
        (item, index) => index !== targetIndex
      )
    }
  }
}
</script>

<style scoped>
.footer-content-holder {
  margin-bottom: 0.5rem;
  position: relative;
}

.footer-content-holder > .delete {
  position: absolute;
  bottom: 0.1rem;
  right: 0.8rem;
}
</style>
