<template>
  <div class="mb-5">
    <th-input-title :title="$t('pages.document_designer.logo')" />
    <el-upload
      ref="uploader"
      class="upload-logo"
      drag
      action=""
      :limit="1"
      :auto-upload="false"
      :thumbnail-mode="true"
      list-type="picture"
      :show-file-list="false"
      :on-error="onError"
      :on-change="onChange"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :file-list="fileList"
      accept="image/*"
    >
      <div v-if="!value">
        <el-icon><Upload /></el-icon>
        <div class="el-upload__text">
          <span>{{ $t('components.image_upload.drag_drop') }}</span>
          {{ ' ' }}
          <em>{{ $t('components.image_upload.click_to_upload') }}</em>
          <p>
            {{ $t('components.image_upload.size_message.short') }}
          </p>
        </div>
      </div>
      <img v-else class="el-upload__text" :src="value" alt="logo" />
      <div class="actions-wrap">
        <el-button
          v-if="!!value"
          class="img-delete-btn"
          icon="Delete"
          text
          @click.stop="handleRemove"
        />
      </div>
    </el-upload>
  </div>
</template>

<script>
const FILE_SIZE_LIMIT = 0.5

export default {
  data() {
    return {
      fileList: []
    }
  },
  computed: {
    value: {
      get() {
        return this.$store.state.Templates.currentTemplate.options.logo || ''
      },
      set(value) {
        this.$store.commit('Templates/setCurrentTemplateOptionsProp', {
          logo: value
        })
      }
    }
  },
  methods: {
    handlePreview() {},
    handleRemove() {
      this.value = ''
    },
    hasFileSizeExceeded(fileSize) {
      return fileSize / 1024 / 1024 > FILE_SIZE_LIMIT
    },
    onChange(file) {
      this.$refs.uploader.clearFiles()
      try {
        // before-upload and on-exceeded hooks are not working, so we have to validate file size manually on change.
        if (this.hasFileSizeExceeded(file.size)) {
          throw new Error(
            this.$t('components.image_upload.error_message.exceeds_limit')
          )
        }
        // the following might now be available in some browsers
        const reader = new FileReader()
        reader.readAsDataURL(file.raw)
        reader.onload = () => {
          this.value = reader.result
        }

        reader.onerror = function (err) {
          throw err
        }
      } catch (err) {
        this.onError(err)
      }
    },
    onError(err) {
      this.$notify.error({
        title: 'Error',
        message: err.message
      })
    }
  }
}
</script>

<style scoped>
.el-upload__text {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.upload-logo :deep(.el-upload--picture) {
  width: 100%;
}

.upload-logo :deep(.el-upload-dragger) {
  width: 100%;
}

.img-delete-btn {
  font-size: 20px;
  padding: 10px;
  position: absolute;
  right: 0;
  top: 0;
}

.img-delete-btn:hover {
  color: red;
}

.image-upload-text {
  margin: 0;
  display: flex;
  flex-direction: column;
}

.image-upload-text :deep(p) {
  margin: 0;
  color: #a7abb1;
}
</style>
