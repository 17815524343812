<template>
  <div class="mb-5">
    <th-modal
      :title="$t('pages.document_designer.currency_formats.add_currency')"
      width="500px"
      name="currency"
      @close="handleClose"
    >
      <th-input-title :title="$t('pages.document_designer.preview')" />
      <div class="example-result mb-5">
        {{
          example_result ||
          $t('pages.document_designer.currency_formats.example')
        }}
      </div>

      <el-form>
        <!-- Currency -->
        <el-form-item
          for="currency"
          :label="$t('pages.document_designer.currency_formats.currency.title')"
        >
          <el-select
            id="currency"
            v-model="result.currency"
            v-cancel-read-only
            filterable
            allow-create
            default-first-option
            :placeholder="
              $t(
                'pages.document_designer.currency_formats.currency.placeholder'
              )
            "
          >
            <el-option
              v-for="item in currency_options"
              :key="item.value"
              :label="item.value"
              :value="item.value"
            />
          </el-select>
        </el-form-item>

        <!-- Symbol -->
        <el-form-item
          for="symbol"
          :label="$t('pages.document_designer.currency_formats.symbol.title')"
        >
          <el-input
            id="symbol"
            v-model="result.symbol"
            :placeholder="
              $t('pages.document_designer.currency_formats.symbol.placeholder')
            "
            clearable
          />
        </el-form-item>

        <!-- Decimal Symbol -->
        <el-form-item
          for="decimal"
          :label="$t('pages.document_designer.currency_formats.decimal.title')"
        >
          <el-input
            id="decimal"
            v-model="result.decimal"
            :placeholder="
              $t('pages.document_designer.currency_formats.decimal.placeholder')
            "
            clearable
          />
        </el-form-item>

        <!-- Thousand Seperator -->
        <el-form-item
          for="thousand"
          :label="$t('pages.document_designer.currency_formats.thousand.title')"
        >
          <el-input
            id="thousand"
            v-model="result.thousand"
            :placeholder="
              $t(
                'pages.document_designer.currency_formats.thousand.placeholder'
              )
            "
            clearable
          />
        </el-form-item>

        <!-- Decimal Places -->
        <el-form-item
          for="precision"
          :label="$t('pages.document_designer.currency_formats.precision')"
        >
          <el-select
            id="precision"
            v-model="result.precision"
            :placeholder="$t('common.interactions.buttons.select')"
          >
            <el-option
              v-for="item in [{ value: 2 }, { value: 0 }, { value: 1 }]"
              :key="item.value"
              :label="item.value"
              :value="item.value"
            />
          </el-select>
        </el-form-item>

        <!-- Format -->
        <el-form-item
          for="format"
          :label="$t('pages.document_designer.currency_formats.format')"
        >
          <el-select
            id="format"
            v-model="result.format"
            :placeholder="$t('common.interactions.buttons.select')"
          >
            <el-option
              v-for="item in [
                { value: '%v %s' },
                { value: '%v%s' },
                { value: '%s%v' },
                { value: '%s %v' }
              ]"
              :key="item.value"
              :label="item.value"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-form>

      <template #footer>
        <el-button @click="$thModal.hide('currency')">
          {{ $t('pages.document_designer.currency_formats.cancel') }}
        </el-button>
        <el-button
          type="primary"
          :disabled="!result.currency"
          @click="addFormat"
        >
          {{ $t('pages.document_designer.currency_formats.confirm') }}
        </el-button>
      </template>
    </th-modal>

    <th-input-title
      :title="$t('pages.document_designer.currency_formats.title')"
    />

    <!-- Empty -->
    <div v-if="!formats || !formats.length" class="empty mb-2">
      {{ $t('pages.document_designer.currency_formats.empty') }}
    </div>

    <!-- Rows -->
    <el-collapse v-else class="item-list mb-2" accordion>
      <el-collapse-item
        v-for="(item, index) in formats"
        :key="index"
        :name="item.currency"
      >
        <template #title>
          <div class="title-with-example">
            <span>{{ item.currency || '-' }}</span>
            <div>{{ formatExample(item) || '-' }}</div>
          </div>
        </template>
        <div class="options-list">
          <div>
            <span>
              {{
                $t('pages.document_designer.currency_formats.symbol.title') +
                ':'
              }}
            </span>
            <span> {{ item.symbol || '-' }} </span>
          </div>
          <div>
            <span>
              {{
                $t('pages.document_designer.currency_formats.decimal.title') +
                ':'
              }}
            </span>
            <span>{{ item.decimal || '-' }}</span>
          </div>
          <div>
            <span>
              {{
                $t('pages.document_designer.currency_formats.thousand.title') +
                ':'
              }}
            </span>
            <span>{{ item.thousand || '-' }}</span>
          </div>
          <div>
            <span>
              {{
                $t('pages.document_designer.currency_formats.precision') + ':'
              }}
            </span>
            <span>{{ item.precision || '-' }}</span>
          </div>
          <div>
            <span>
              {{ $t('pages.document_designer.currency_formats.format') + ':' }}
            </span>
            <span>{{ item.format || '-' }}</span>
          </div>
        </div>
        <nav class="item-nav">
          <el-button
            plain
            icon="Edit"
            class="el-button--text-icon"
            @click="openItem(index, item)"
          />
          <el-button
            plain
            icon="Delete"
            class="el-button--text-icon"
            @click="deleteItem(index, item)"
          />
        </nav>
      </el-collapse-item>
    </el-collapse>

    <!-- Add -->
    <el-button
      class="el-button--primary-icon"
      plain
      icon="Plus"
      @click="openFormat"
    />
  </div>
</template>

<script>
import { currencies, currencySymbol } from '@/constants'

function normalizeFormatExample(val) {
  const valObj = {}
  Object.entries(val).forEach(([key, value]) => {
    valObj[key] = value === '' ? undefined : value
  })
  return valObj
}

export default {
  data() {
    return {
      result: {
        currency: undefined,
        symbol: undefined,
        decimal: undefined,
        thousand: '',
        precision: 2,
        format: '%v %s'
      },
      example_result: ''
    }
  },
  computed: {
    formats: {
      get() {
        return (
          this.$store.state.Templates.currentTemplate.options
            .currency_formats || []
        )
      },
      set(value) {
        this.$store.commit('Templates/setCurrentTemplateOptionsProp', {
          currency_formats: value
        })
      }
    },
    currency_options: {
      get() {
        return currencies
          .map((value) => ({ value }))
          .filter((item) => {
            return !(
              this.$store.state.Templates.currentTemplate.options
                .currency_formats || []
            ).find((format) => {
              return item.value === format.currency
            })
          })
      },
      set(value) {
        return value
      }
    }
  },
  watch: {
    result: {
      handler: function (val, oldVal) {
        this.example_result = this.formatExample(val)
      },
      deep: true
    }
  },
  methods: {
    openFormat() {
      this.clearResult()
      this.$thModal.show('currency')
    },
    addFormat() {
      if (!this.result.currency) {
        this.$message.error(
          this.$t('pages.document_designer.currency_formats.missing_error')
        )
        return
      }

      this.$thModal.hide('currency')
      const filteredFormats = this.formats.filter(
        (format) => format.currency !== this.result.currency
      )

      this.formats = [
        ...filteredFormats,
        {
          decimal: '.',
          ...this.result
        }
      ]

      this.clearResult()
    },
    handleClose() {
      this.clearResult()
      this.$thModal.hide('currency')
    },
    clearResult() {
      this.example_result = ''
      this.result = {
        currency: undefined,
        symbol: undefined,
        decimal: undefined,
        thousand: '',
        precision: 2,
        format: '%v %s'
      }
    },
    formatExample(val) {
      const {
        currency,
        symbol,
        decimal,
        thousand,
        precision,
        format
      } = normalizeFormatExample(val)

      /*
       * this is a hack.
       * since it is only for printing an example (fack number) will generate cents value as a string with the decimal point
       * by mutating the array length, we are left with the items to show
       * for example: string with array of  ['9', '9']
       *  precision of 2 => ['9', '9'] => '.99'
       *  precision of 1 => ['9']=> '.9'
       *  precision of 0 => [] =>''
       */

      let cents = ['9', '9']
      cents.length = precision
      cents = cents.length ? (decimal || '.') + cents.join('') : '' //e.g. '.99'

      const priceValue = `1${thousand || ''}099${cents}` //e.g. 1,099.99
      const priceSymbol = symbol || currencySymbol[currency] || '' //e.g. 'USD' or '$'
      return format.replace('%v', priceValue).replace('%s', priceSymbol) //e.g. '1,099.99 $'
    },
    deleteItem(index, item) {
      this.formats = this.formats.filter((formats, formatIndex) => {
        return formatIndex !== index
      })
    },
    openItem(index, item) {
      this.result = JSON.parse(JSON.stringify(item))
      this.$thModal.show('currency')
    }
  }
}
</script>

<style scoped>
nav.item-list-nav > * {
  width: 100%;
}

.empty {
  width: 100%;
  min-height: 4em;
  border: 1px dashed var(--border-color) !important;
  border-radius: var(--border-radius);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
}

.example-result {
  width: 100%;
  min-height: 3em;
  border: 2px dashed var(--border-color) !important;
  border-radius: var(--border-radius);
  margin-top: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-with-example {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.title-with-example div {
  color: #ccc;
  position: absolute;
  display: inline-flex;
  right: 3em;
}

.title-with-example span {
  margin-left: 1em;
  font-weight: bold;
}

.options-list {
  margin-left: 1em;
}
.options-list div span:first-child {
  font-weight: bold;
}
.options-list div span:nth-child(2) {
  margin-left: 0.2em;
}

.item-nav {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 1em;
}
</style>
