<template>
  <div>
    <h3>{{ $t('pages.document_designer.headersTitle') }}</h3>
    <logo />
    <address-self />
    <address-recipient />
    <transaction-number />
    <cashier />
    <customer-number v-if="templateType === 'invoices'" />
    <date-of-issue />
    <date-of-delivery />
    <slot name="additionals" />
  </div>
</template>

<script>
import Logo from './components/logo.vue'
import AddressSelf from './components/address-self.vue'
import AddressRecipient from './components/address-recipient.vue'
import TransactionNumber from './components/transaction-number.vue'
import Cashier from './components/cashier.vue'
import CustomerNumber from './components/customer-number.vue'
import DateOfIssue from './components/date-of-issue.vue'
import DateOfDelivery from './components/date-of-delivery.vue'

export default {
  components: {
    Logo,
    AddressSelf,
    AddressRecipient,
    TransactionNumber,
    Cashier,
    CustomerNumber,
    DateOfIssue,
    DateOfDelivery
  },
  computed: {
    templateType() {
      return this.$route.name
    }
  }
}
</script>
