<template>
  <div class="mb-5">
    <th-input-title
      for="dateOfIssueTitle"
      :title="$t('pages.document_designer.dateOfIssueTitle')"
    />
    <el-input
      id="dateOfIssueTitle"
      v-model="value"
      :placeholder="$t('pages.document_designer.dateOfIssueTitle')"
      maxlength="32"
    />
  </div>
</template>

<script>
export default {
  computed: {
    value: {
      get() {
        return (
          this.$store.state.Templates.currentTemplate.options
            .date_of_issue_title || ''
        )
      },
      set(value) {
        this.$store.commit('Templates/setCurrentTemplateOptionsProp', {
          date_of_issue_title: value
        })
      }
    }
  }
}
</script>
