<template>
  <div class="mb-5">
    <th-input-title
      for="dateFormat"
      :title="$t('pages.document_designer.dateFormat')"
    />
    <el-input
      id="dateFormat"
      v-model="value"
      placeholder="DD.MM.YY HH:mm"
      maxlength="32"
    />
  </div>
</template>

<script>
export default {
  computed: {
    value: {
      get() {
        return (
          this.$store.state.Templates.currentTemplate.options.date_format ||
          'DD.MM.YY HH:mm'
        )
      },
      set(value) {
        this.$store.commit('Templates/setCurrentTemplateOptionsProp', {
          date_format: value
        })
      }
    }
  }
}
</script>
