<template>
  <div>
    <h3>{{ $t('pages.document_designer.footer') }}</h3>
    <trailer-text />
    <footer-content />
  </div>
</template>

<script>
import TrailerText from './components/trailer-text.vue'
import FooterContent from './components/footer-content.vue'
export default {
  components: {
    TrailerText,
    FooterContent
  }
}
</script>
